<template>
  <el-card :body-style="{ padding: '2px' }">
    <div>
      <el-row>
        <el-col :span="2">
          <el-tag>只展示厂商</el-tag>
        </el-col>
        <el-col :span="2">
          <el-tag>只显示中国</el-tag>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-form
        :model="listQuery"
        ref="loginForm"
        label-width="0px"
        :rules="rules"
        style="padding-top: 2px"
      >
        <el-row style="margin-top: 0; padding-top: 0">
          <el-col :span="2">
            <el-switch
              v-model="switchStatus"
              active-color="#13ce66"
              @change="switchChange"
            />
          </el-col>
          <el-col :span="2">
            <el-switch
              v-model="showCountry"
              active-color="#13ce66"
              @change="showCountryHandle"
            />
          </el-col>
          <el-col :span="3" :offset="1">
            <el-form-item prop="id">
              <el-input
                v-model.number="listQuery.id"
                placeholder="厂商ID"
                style="width: 300px"
                type="number"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3" :offset="1">
            <el-form-item prop="name">
              <el-input
                v-model="listQuery.name"
                placeholder="厂商名"
                style="width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="3" :offset="1" v-show="!switchStatus">
            <el-form-item prop="developer">
              <el-input
                v-model="listQuery.developer"
                placeholder="开发商账号"
                style="width: 300px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2" :offset="1">
            <el-form-item>
              <el-button @click="searchList">搜索</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>

  <el-card :body-style="{ padding: '2px' }">
    <div>
      <div>
        <el-table
          :data="tableData"
          style="width: 100%"
          max-height="720px"
          :key="key2"
          @cell-click="rowClick"
          v-loading="loading"
        >
          <el-table-column prop="id" label="厂商ID" width="120" />
          <el-table-column prop="name" label="厂商" width="300">
            <template v-slot="{ row }" v-if="inputStatus">
              <el-input
                v-model="row.name"
                class="input-text"
                @blur="modifyManufacturer(row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="developer"
            label="开发商"
            width="400"
            v-if="!switchStatus"
          />
          <el-table-column prop="country" label="国家" width="400" />
          <el-table-column label="Operate" width="200" v-if="!switchStatus">
            <template v-slot="{ row }">
              <el-button @click="showDialog(row)">更改厂商</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-card>
  <el-card :body-style="{ padding: '6px' }">
    <el-pagination
      v-model:currentPage="listQuery.page"
      :page-sizes="[10, 20, 50, 100]"
      v-model:page-size="listQuery.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>
  <el-dialog v-model="dialogShow">
    <el-form v-model="formData" :label-width="85" label-position="left">
      <el-row justify="center"
        ><span style="color: red; margin-bottom: 20px; font-size: 18px"
          >修改厂商名，同时会修改该开发商账号下所有游戏的厂商信息，谨慎修改！</span
        ></el-row
      >
      <el-row justify="center">
        <el-form-item prop="id" label="厂商ID">
          <el-input v-model="formData.id" disabled />
        </el-form-item>
      </el-row>
      <el-row justify="center">
        <el-form-item prop="name" label="厂商名">
          <el-input v-model="formData.name" />
        </el-form-item>
      </el-row>
      <el-row justify="center">
        <el-form-item prop="developer" label="开发商账号">
          <el-input v-model="formData.developer" disabled style="width: 150%" />
        </el-form-item>
      </el-row>
      <el-row justify="center">
        <el-form-item prop="country" label="国家">
          <el-input v-model="formData.country" style="width: 150%" />
        </el-form-item>
      </el-row>
      <el-row justify="center">
        <el-form-item label-width="0">
          <el-button @click="modifyManufacturerDeveloper">提交</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  getManufacturerDeveloperList,
  updateManufacturer,
  getManufacturerList,
} from "../api/app";
import { checkPermission } from "../utils/auth";
import enumValue from "../utils/enum";
import { messageWarning } from "../utils/message";
import { deepCopy } from "../utils/util";
const defaultPage = 1;
const defaultSize = 100;
var initListQuery = {
  id: undefined,
  name: "",
  developer: "",
  country: "",
  page: defaultPage,
  pageSize: defaultSize,
};
export default {
  data() {
    return {
      loading: false,
      dialogShow: false,
      totalRecord: undefined,
      switchStatus: false,
      showCountry: false,
      switchValue: true,
      key2: "",
      listQuery: deepCopy(initListQuery),
      tableData1: [],
      tableData2: [],
      formData: {},
      inputStatus:
        !this.switchStatus && checkPermission(enumValue.permissionAdmin),
    };
  },
  created() {
    const switchStatus = this.$route.query.switchStatus;
    const listQuery = this.$route.query.listQuery;
    if (listQuery != undefined) {
      this.listQuery = JSON.parse(listQuery);
      if (this.listQuery.country) {
        this.showCountry = true;
      }
    }
    if (switchStatus == undefined && listQuery == undefined) {
      // this.getList();
    }else if (!switchStatus) {
      this.getList();
    } else {
      if (switchStatus == "true") {
        this.switchStatus = true;
        this.getSimpleList();
      } else {
        this.getList();
      }
    }
  },
  watch: {
    $route(to, from) {
      //监听相同路由下参数变化的时候，从而实现异步刷新
      //重新获取数据
      if (to.name == from.name) {
        if (this.$route.query.listQuery != undefined) {
          this.listQuery = JSON.parse(this.$route.query.listQuery);
        } else {
          this.listQuery = deepCopy(initListQuery);
        }
        if (this.listQuery.country != "") {
          this.showCountry = true;
        } else {
          this.showCountry = false;
        }
        if (!this.$route.query.switchStatus) {
          this.switchStatus = false;
          this.getList();
        } else {
          if (this.$route.query.switchStatus == "true") {
            this.switchStatus = true;
            this.getSimpleList();
          } else {
            this.switchStatus = false;
            this.getList();
          }
        }
      }
    },
  },
  methods: {
    // 获取厂商开发商列表
    getList() {
      this.loading = true;
      getManufacturerDeveloperList(this.listQuery)
        .then((resp) => {
          this.tableData = resp.data.data.records;
          this.totalRecord = resp.data.data.total;
          this.key2 = Math.random();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 获取厂商列表
    getSimpleList() {
      this.loading = true;
      getManufacturerList(this.listQuery)
        .then((resp) => {
          this.tableData = resp.data.data.records;
          this.totalRecord = resp.data.data.total;
          this.key2 = Math.random();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 进入厂商账号列表
    rowClick(row, column) {
      // localStorage.setItem("m-list-switch-status", this.switchStatus);
      if (this.switchStatus) {
        // 跳转厂商开发商列表
        let params = deepCopy(this.listQuery);
        params.name = row.name;
        let routeUrl = this.$router.resolve({
          name: "manufacturerList",
          query: {
            switchStatus: false,
            listQuery: JSON.stringify(params),
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        // 跳转厂商app列表
        if (
          (column.property != "name" && column.label != "Operate") ||
          this.switchStatus == true ||
          (column.property == "name" &&
            !checkPermission(enumValue.permissionAdmin))
        ) {
          let queryInfo = {
            id: row.id,
            name: row.name,
            developer: row.developer,
          };
          if (column.property == "developer") {
            queryInfo["searchDeveloper"] = row.developer;
          }

          let routeUrl = this.$router.resolve({
            name: "manufacturerAppList",
            query: queryInfo,
          });
          window.open(routeUrl.href, "_blank");
        }
      }
    },
    // 修改厂商名称
    modifyManufacturer(row) {
      if (!checkPermission(enumValue.permissionAdmin)) {
        messageWarning("权限不足！！！");
        return;
      }
      updateManufacturer({
        id: row.id,
        name: row.name,
        developer: row.developer,
      }).then(() => {
        this.getList();
      }).catch((error) => {
        console.log(error)
      })
    },
    // 页面条数变化
    handleSizeChange(size) {
      this.listQuery.pageSize = size;
      this.$router.push({
        name: "manufacturerList",
        query: {
          switchStatus: this.switchStatus,
          listQuery: JSON.stringify(this.listQuery),
        },
      });
    },
    // 翻页
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.$router.push({
        name: "manufacturerList",
        query: {
          switchStatus: this.switchStatus,
          listQuery: JSON.stringify(this.listQuery),
        },
      });
    },
    // 显示dialog
    showDialog(row) {
      if (!checkPermission(enumValue.permissionAdmin)) {
        messageWarning("权限不足！！！");
        return;
      }
      this.dialogShow = true;
      this.formData = row;
    },
    // 修改厂商名2
    modifyManufacturerDeveloper() {
      if (!checkPermission(enumValue.permissionAdmin)) {
        messageWarning("权限不足！！！");
        return;
      }
      this.formData.updateDeveloper = true;
      updateManufacturer(this.formData).then(() => {
        this.dialogShow = false;
        this.getList();
      });
    },
    // switch开关切换
    switchChange() {
      const country = this.listQuery.country;
      this.listQuery = deepCopy(initListQuery);
      this.listQuery.country = country;
      this.$router.push({
        name: "manufacturerList",
        query: {
          switchStatus: this.switchStatus,
          listQuery: JSON.stringify(this.listQuery),
        },
      });
    },
    searchList() {
      this.listQuery.page = defaultPage;
      this.$router.push({
        name: "manufacturerList",
        query: {
          switchStatus: this.switchStatus,
          listQuery: JSON.stringify(this.listQuery),
          key: Math.random(),
        },
      });
    },
    // 国家切换
    showCountryHandle() {
      if (this.showCountry == true) {
        this.listQuery.country = "CN";
      } else {
        this.listQuery.country = "";
      }
      this.$router.push({
        name: "manufacturerList",
        query: {
          switchStatus: this.switchStatus,
          listQuery: JSON.stringify(this.listQuery),
        },
      });
    },
  },
};
</script>
<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-text :deep(.el-input__inner) {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px;
  width: 100%;
}
.el-card {
  margin: 4px;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
